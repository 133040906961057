import { Inject, Injectable, InjectionToken } from "@angular/core"
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http"
import { EMPTY, Observable, throwError, TimeoutError } from "rxjs"
import { environment } from "../../environments/environment"
import { catchError, timeout } from "rxjs/operators";
import { Router } from "@angular/router";

export const DEFAULT_TIMEOUT = new InjectionToken<number>('defaultTimeout');

@Injectable({
  providedIn: "root",
})
export class ApiInterceptor implements HttpInterceptor {
  constructor(
    @Inject(DEFAULT_TIMEOUT) private defaultTimeout: number,
    private router: Router,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    req = req.clone({
      setHeaders: {
        // Authorization: `Basic ${token}`,
        "X-Api-Key": environment.api.key,
      },
    })

    return next.handle(req).pipe(
      timeout(this.defaultTimeout),
      catchError((err, caught) => {
        if (err instanceof TimeoutError){
          this.router.navigateByUrl("/maintenance")
        }
        return throwError(err)
      })
    );
  }
}
