<ng-container *ngFor="let segment of data; let index = index">
  <div class="path">
    <div class="icon-path"></div>
    <span
      ><i>{{ segment.label }}</i> <br /><span style="color: gray"
        >de {{ segment.start_at | dateTz : "HH:mm" }} à
        {{ segment.end_at | dateTz : "HH:mm" }}</span
      ></span
    >
  </div>
  <ng-container *ngIf="index < data.length - 1">
    <div class="path" *ngIf="index === 0 || hasMuseumStop">
      <div [class]="index > 0 ? 'icon-museum' : 'icon-sun'"></div>
      <span *ngIf="index > 0">
        <i>Visite guidée au Musée La Mine</i> : 1h 30min
      </span>
      <span *ngIf="index == 0">
        <i>Temps libre au Quai des Grands Balcons</i> :
        {{ getPathDuration(data[index].end_at, data[index + 1].start_at) }}
      </span>
    </div>
  </ng-container>
</ng-container>
