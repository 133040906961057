import { Component, OnInit } from "@angular/core"
import { VelOrderService } from "../../services/vel-order.service"
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms"

import { VelApiService } from "../../services/vel-api.service"
import { Router } from "@angular/router"
import { DomainFormatErrorCode, MAIL_REGEXP } from "../../models/user.model"
import { formatErrorMessage, showFormErrors } from "../../components/helper"

const EmailMatchingValidator = (
  form: AbstractControl
): ValidationErrors | null => {
  let error = null
  if (form.get("email")?.value !== form.get("email2")?.value) {
    error = { [DomainFormatErrorCode.EmailMismatch]: true }
  }
  form.get("email2")?.setErrors(error)
  return null
}

const emailValidator = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value
    return MAIL_REGEXP.test(value)
      ? null
      : { [DomainFormatErrorCode.EmailFormat]: true }
  }
}

@Component({
  selector: "app-user-page",
  templateUrl: "./user-information-page.component.html",
  styleUrls: ["./user-information-page.component.scss"],
})
export class UserInformationPageComponent implements OnInit {
  formatErrorMessage = formatErrorMessage

  loading = false
  fg: UntypedFormGroup = this.formBuilder.group(
    {
      email: ["", emailValidator()],
      email2: ["", null],
      postal_code: ["", Validators.maxLength(15)],
      first_name: ["", null],
      last_name: ["", null],
      newsletter_accepted: [false, null],
    },
    {
      validators: EmailMatchingValidator,
    }
  )

  constructor(
    private velOrderService: VelOrderService,
    private velApiService: VelApiService,
    private router: Router,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.velOrderService.checkOrderConsistency(4)
    const info = this.velOrderService.getCustomer()
    this.fg.patchValue({
      ...info,
      email2: info.email,
    })
  }

  next(): void {
    if (this.fg.valid) {
      this.loading = true
      this.velOrderService
        .setUserInfo(this.fg.value)
        .finally(() => (this.loading = false))
    } else {
      showFormErrors(this.fg)
    }
  }
}
