import { Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import { Observable } from "rxjs"
import { environment } from "../../environments/environment"
import { take } from "rxjs/operators"
import { MessageService } from "primeng/api"

import { RateModel } from "../models/rate"
import { UnavailableDateModel } from "../models/unavailableDates"
import { UnavailableDatesFactory } from "../models/unavailableDatesFactory"
import { ProductFactory } from "../models/product.factory"
import { TimeslotsFactory } from "../models/timeslots.factory"
import { TimeSlotsModel } from "../models/timeslots"
import { ProductModel } from "../models/product"
import { OrderModel } from "../models/order"
import { UserModel } from "../models/user.model"
import { DateTz } from "../dates/DateTz"
import { Router } from "@angular/router"

export interface RowCommand {
  product_id: string
  parent_row_id?: string
  prices: Array<{
    price_id: string
    quantity: number
  }>
  timeslot_id?: string
  indicative_date?: string
}

export interface PaymentReturn {
  id: string
  gateway: string
  return_url: string
  payment_link_url: string
}

export interface Document {
  id: string
  type: string
  label: string
  download_url: string
}

const URL = environment.api.url

@Injectable({
  providedIn: "root",
})
export class VelApiService {
  constructor(
    protected http: HttpClient,
    private messageService: MessageService,
    private router: Router
  ) {}

  getOrder(orderId: string, showError = true): Promise<OrderModel> {
    return this.handlingXhrResponse<DataResponse<OrderModel>>(
      this.http.get<DataResponse<OrderModel>>(`${URL}/shop/orders/${orderId}`),
      showError
    ).then((data) => wrapperOrder(data.data))
  }

  createNewOrder(): Promise<OrderModel> {
    console.log("createNewOrder => create a new order!!!!")
    return this.handlingXhrResponse<DataResponse<OrderModel>>(
      this.http.post<DataResponse<OrderModel>>(`${URL}/shop/orders`, null)
    ).then((data) => wrapperOrder(data.data))
  }

  updateRowOrder(
    params: RowCommand,
    orderId: string,
    rowId: string
  ): Promise<any> {
    return this.handlingXhrResponse<DataResponse<any>>(
      this.http.put<DataResponse<any>>(
        `${URL}/shop/orders/${orderId}/rows/${rowId}`,
        {
          ...params,
          indicative_date: params.indicative_date
            ? params.indicative_date
            : undefined,
        }
      )
    ).then((data) => data.data)
  }

  createRowOrder(params: RowCommand, orderId: string): Promise<any> {
    return this.handlingXhrResponse<DataResponse<any>>(
      this.http.post<DataResponse<any>>(
        `${URL}/shop/orders/${orderId}/rows`,
        params
      )
    ).then((data) => data.data)
  }

  confirmOrderRow(orderId: string, rowId: string): Promise<any> {
    return this.handlingXhrResponse<DataResponse<any>>(
      this.http.post<DataResponse<any>>(
        `${URL}/shop/orders/${orderId}/rows/${rowId}/confirm`,
        null
      )
    ).then((data) => data.data)
  }

  getProducts(): Promise<PaginatedList<ProductModel>> {
    return this.handlingXhrResponse<DataListResponse<ProductModel>>(
      this.http.get<DataListResponse<ProductModel>>(`${URL}/shop/products`)
    ).then((data) =>
      new ListAllBuilder<ProductModel>().toList(data, ProductFactory.wrapper)
    )
  }

  getUnavailableDateByMonth(
    monthId: number,
    year: number,
    productId: string
  ): Promise<PaginatedList<UnavailableDateModel>> {
    return this.handlingXhrResponse<DataListResponse<UnavailableDateModel>>(
      this.http.get<DataListResponse<UnavailableDateModel>>(
        `${URL}/shop/products/${productId}/dates?month=${year}-${(
          "" + monthId
        ).padStart(2, "0")}`
      )
    ).then((data) =>
      new ListAllBuilder<UnavailableDateModel>().toList(
        data,
        UnavailableDatesFactory.wrapper
      )
    )
  }

  getTimeslots(
    productId: string,
    date: DateTz
  ): Promise<PaginatedList<TimeSlotsModel>> {
    const formattedDate = date.toApiFormat()
    return this.handlingXhrResponse<DataListResponse<TimeSlotsModel>>(
      this.http.get<DataListResponse<TimeSlotsModel>>(
        `${URL}/shop/products/${productId}/timeslots?date=${formattedDate}`
      )
    ).then((data) =>
      new ListAllBuilder<TimeSlotsModel>().toList(
        data,
        TimeslotsFactory.wrapper
      )
    )
  }

  getPrices(bundleId: string, date: DateTz): Promise<PaginatedList<RateModel>> {
    const formattedDate = date.toApiFormat()
    return this.handlingXhrResponse<DataListResponse<RateModel>>(
      this.http.get<DataListResponse<RateModel>>(
        `${URL}/shop/products/${bundleId}/prices?date=${formattedDate}`
      )
    )
      .then((data) => new ListAllBuilder<RateModel>().toList(data))
      .then((data) => {
        data.list.map((price) => {
          if (price.requirements.length > 0) {
            price.tooltip = "Uniquement vendu avec "
            const labels: string[] = []
            price.requirements.forEach((requirement) => {
              labels.push(`"${requirement.label}"`)
            })
            price.tooltip += labels.join(" ou ")
          }
        })
        return data
      })
  }

  setInfoCustomer(params: UserModel, orderId: string): Promise<any> {
    return this.handlingXhrResponse<DataResponse<any>>(
      this.http.put<DataResponse<any>>(
        `${URL}/shop/orders/${orderId}/customer`,
        params
      )
    ).then((data) => data.data)
  }

  // confirmOrder(orderId: string): Promise<any> {
  //   return this.handlingXhrResponse<DataResponse<any>>(
  //     this.http.post<DataResponse<any>>(
  //       `${URL}/shop/orders/${orderId}/confirm`,
  //       null
  //     ),
  //     false
  //   )
  // }

  cancelOrder(orderId: string): Promise<any> {
    return this.handlingXhrResponse<DataResponse<any>>(
      this.http.post<DataResponse<any>>(
        `${URL}/shop/orders/${orderId}/cancel`,
        null
      ),
      false
    )
  }

  makePayment(returnUrl: string, orderId: string): Promise<PaymentReturn> {
    return this.handlingXhrResponse<DataResponse<PaymentReturn>>(
      this.http.post<DataResponse<PaymentReturn>>(
        `${URL}/shop/orders/${orderId}/payment-links`,
        {
          gateway: "PayboxSystem",
          return_url: returnUrl,
        }
      )
    ).then((data) => data.data)
  }

  getTimeslotDetails(
    productId: string,
    timeslotId: string
  ): Promise<TimeSlotsModel> {
    return this.handlingXhrResponse<DataResponse<TimeSlotsModel>>(
      this.http.get<DataResponse<TimeSlotsModel>>(
        `${URL}/shop/products/${productId}/timeslots/${timeslotId}`
      )
    ).then((data) => TimeslotsFactory.wrapper(data.data))
  }

  isMobile(): boolean {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  }

  getDocuments(orderId: string): Promise<Array<Document>> {
    return this.handlingXhrResponse<DataResponse<Array<Document>>>(
      this.http.get<DataResponse<Array<Document>>>(
        `${URL}/shop/orders/${orderId}/delivery-documents`
      )
    ).then((data) => data.data)
  }

  protected handlingXhrResponse<T>(
    httpResponse: Observable<T>,
    showError = true
  ): Promise<T> {
    return new Promise((resolve, reject) => {
      httpResponse.pipe(take(1)).subscribe(
        (res) => resolve(res),
        (error) => {
          const errorMessage = this.getErrorMessage(error)
          if (showError) {
            this.messageService.add({
              severity: "error",
              summary: "Erreur",
              detail: errorMessage,
              life: 4000,
            })
          }
          reject(errorMessage)
        }
      )
    })
  }

  private getErrorMessage(error: any): any {
    let message = error?.error?.data?.message
    if (!message || error.status === 500) {
      message =
        "Le service rencontre un problème, merci de rechargez la page ou de réessayez plus tard."
    }
    if (error.status === 404) {
      this.router.navigateByUrl("/maintenance")
    }
    return message
  }
}

const wrapperOrder = (order: any): OrderModel => {
  return order
}

class ListAllBuilder<T> {
  toList(
    dataList: DataListResponse<T>,
    wrapper?: (input: any) => T
  ): PaginatedList<T> {
    return {
      list: wrapper
        ? dataList.data.map((item) => wrapper(item))
        : dataList.data,
      paging: DataBuilder.toPaging(dataList.paging),
    }
  }
}

class DataBuilder {
  static toPaging(response: PagingResponse): Paging {
    return {
      page: response.page,
      limit: response.limit,
      count: response.count,
      totalCount: response.total_count,
    }
  }
}

export interface Paging {
  page: number
  limit: number
  count: number
  totalCount: number
}

export interface PaginatedList<T> {
  list: T[]
  paging: Paging
}

export interface PagingResponse {
  page: number
  limit: number
  count: number
  total_count: number
}

export interface DataListResponse<T> {
  data: T[]
  paging: PagingResponse
}

export interface DataResponse<T> {
  data: T
}
