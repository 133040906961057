<div class="text">
  <div>
    Le trajet sélectionné vous permet d’ajouter un maximum de
    {{ remainingSeats }} voyageurs.
  </div>
  <br />
  <i style="display: none">
    <div>
      Profitez d’un voyage commenté grâce à nos audioguides et découvrez « les
      Petites Histoires du Train ! ».
    </div>
    <div>
      Un contenu exclusif sur les richesses de la Matheysine raconté par le
      journaliste Thomas Hugues.
    </div>
    <div>Il est nécessaire de prévoir des écouteurs (non fournis).</div>
  </i>
</div>

<div class="table">
  <p-table [value]="prices" styleClass="p-datatable-sm">
    <ng-template pTemplate="header">
      <tr>
        <th class="th-width-label"></th>
        <th style="text-align: center">Voyageurs</th>
        <th style="text-align: center">Prix unitaire</th>
        <th style="text-align: center">Total</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-price let-rowIndex="rowIndex">
      <tr>
        <td>
          {{ price.label }}
          <!--          <i-->
          <!--            class="pi pi-info-circle"-->
          <!--            *ngIf="price.tooltip"-->
          <!--            [pTooltip]="price.tooltip"-->
          <!--            tooltipPosition="top"-->
          <!--          ></i>-->
          <div class="description">
            {{ price.description }}
          </div>
          <div class="description">
            {{ price.tooltip }}
          </div>
        </td>
        <td style="text-align: center" class="notranslate">
          <p-dropdown
            [options]="
              quantityChoices[rowIndex]
                ? quantityChoices[rowIndex].filteredOptions
                : []
            "
            [(ngModel)]="price.quantity"
            appendTo="body"
            (onChange)="updateViewAndSave()"
          ></p-dropdown>
        </td>
        <td style="text-align: center">
          {{
            price.net_price.amount === 0 ? "Gratuit" : price.net_price.display
          }}
        </td>
        <td style="text-align: center" class="notranslate">
          {{
            !price.quantity || price.quantity === 0
              ? ""
              : price.net_price.amount === 0
              ? "Gratuit"
              : ((price.quantity * price.net_price.amount) / 100
                | currency : "EUR" : "symbol")
          }}
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-columns>
      <tr class="empty">
        <td [attr.colspan]="4">Aucun tarif</td>
      </tr>
    </ng-template>
    <ng-template pTemplate="footer">
      <tr>
        <td style="visibility: hidden"></td>
        <td style="visibility: hidden"></td>
        <td style="text-align: center">Total TTC</td>
        <td style="text-align: center" class="notranslate">
          {{ total | currency : "EUR" : "symbol" }}
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>

<app-next-button
  (click2)="next()"
  label="Valider les voyageurs"
></app-next-button>
