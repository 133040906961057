import { Router } from "@angular/router"

import { Component, OnInit } from "@angular/core"
import { VelApiService } from "../../services/vel-api.service"

@Component({
  selector: "app-maintenance-page",
  templateUrl: "./maintenance-page.component.html",
  styleUrls: ["./maintenance-page.component.scss"],
})
export class MaintenancePageComponent implements OnInit {
  constructor(private velApiService: VelApiService, private router: Router) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.velApiService
        .getProducts()
        .then(() => this.router.navigateByUrl("/"))
        .catch(() => {})
    }, 1000)
  }
}
