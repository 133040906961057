<p-dialog
  [(visible)]="popupDateVisible"
  [modal]="true"
  header="Sélectionnez la date"
  *ngIf="popupDateVisible"
  class="popup-date"
>
  <app-date-page (dateUpdated)="updateDate($event)"></app-date-page>
</p-dialog>

<div class="container">
  <div class="date-header">
    <button
      type="button"
      class="p-datepicker-prev p-link"
      (click)="yesterday()"
      [ngClass]="{ disablePreviousDay: disablePreviousDay }"
    >
      <span class="p-datepicker-prev-icon pi pi-chevron-left"></span>
    </button>
    <div class="date-middle" (click)="popupDateVisible = true">
      <div class="icon">
        <i class="pi pi-calendar"></i>
      </div>

      <h3>
        {{ date.format("dddd D MMMM YYYY") }}
      </h3>
    </div>
    <button type="button" class="p-datepicker-prev p-link" (click)="tomorrow()">
      <span class="p-datepicker-prev-icon pi pi-chevron-right"></span>
    </button>
  </div>

  <div class="choices">
    <app-timeslot-choice
      [loading]="loading"
      [slots]="slots"
      [title]="title"
      (change)="timeslotUpdate($event)"
    ></app-timeslot-choice>
  </div>

  <h2 *ngIf="dependencies.length > 0">Détails</h2>
  <div class="dependencies">
    <app-dependencies-summary
      [data]="dependencies"
      [hasMuseumStop]="hasMuseumStop"
    ></app-dependencies-summary>
  </div>

  <div class="museum-path" *ngIf="hasMuseumStop">
    <div class="description">
      La visite du musée s’effectue lors du trajet retour. Un trajet vous est
      réservé environ 2 heures après votre arrivée au musée pour regagner la
      Gare de la Mure. Ce choix automatique vous permettra d’effectuer la visite
      guidée complète du musée La Mine Image.
    </div>
  </div>

  <h2>Informations complémentaires</h2>
  <div class="img-container">
    <img src="/assets/journey.png" />
  </div>
  <ul>
    <li>Le trajet aller dure environ 50 minutes</li>
    <li>
      L'accès au Belvédère se fait à pied en 10 à 15 min de marche (300 mètres)
    </li>
    <ng-container *ngIf="hasMuseumStop">
      <li>
        L'arrêt pour la visite au Musée La Mine Image se fait uniquement lors du
        trajet retour
      </li>
    </ng-container>
    <li>
      Accueil PMR : 1 départ sur 2 à partir du 1er départ. 3 places PMR à bord,
      réservation par téléphone uniquement
    </li>
    <li *ngIf="hasMuseumStop">
      <strong>Les chiens ne sont pas acceptés au Musée La Mine Image</strong>
    </li>
  </ul>
  <app-next-button (click2)="next()"></app-next-button>
</div>
