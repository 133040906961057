import { BrowserModule } from "@angular/platform-browser"
import { APP_INITIALIZER, LOCALE_ID, NgModule } from "@angular/core"
import localeFr from "@angular/common/locales/fr"

import { AppRoutingModule } from "./app-routing.module"
import { AppComponent } from "./app.component"

import { DatePageComponent } from "./pages/1-date-page/date-page.component"
import { PaymentPageComponent } from "./pages/5-payment-page/payment-page.component"
import { DownloadPageComponent } from "./pages/6-download-page/download-page.component"
import { StepsModule } from "primeng/steps"

import { VelLayoutComponent } from "./components/vel-layout/vel-layout.component"
import { ButtonModule } from "primeng/button"
import { ProductComponent } from "./components/product/product.component"
import { CalendarModule } from "primeng/calendar"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { BrowserAnimationsModule } from "@angular/platform-browser/animations"
import { TableModule } from "primeng/table"
import { HomePageComponent } from "./pages/0-home-page/home-page.component"
import { UserInformationPageComponent } from "./pages/4-user-information-page/user-information-page.component"
import { PassengersComponent } from "./pages/3-passengers-page/passengers/passengers.component"
import { ToastModule } from "primeng/toast"
import { MessageService } from "primeng/api"
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http"
import { InputTextModule } from "primeng/inputtext"
import { NextButtonComponent } from "./components/next-button/next-button.component"
import { registerLocaleData } from "@angular/common"
import { DropdownModule } from "primeng/dropdown"
import { CheckboxModule } from "primeng/checkbox"
import { ApiInterceptor, DEFAULT_TIMEOUT } from "./services/vel-api-interceptor"
import { DialogModule } from "primeng/dialog"
import { TooltipModule } from "primeng/tooltip"
import { LegalPageComponent } from "./pages/legal-page/legal-page.component"
import { ConfidentialityPageComponent } from "./pages/confidentiality-page/confidentiality-page.component"
import { MaintenancePageComponent } from "./pages/maintenance/maintenance-page.component"
import { DatePipe2 } from "./dates/date-pipe2"
import { TimeslotChoiceComponent } from "./components/timeslot-choice/timeslot-choice.component"
import { VelOrderService } from "./services/vel-order.service"
import { TimeslotPageComponent } from "./pages/2-timeslot-page/timeslot-page.component"
import { DependenciesSummaryComponent } from "./components/dependencies-summary/dependencies-summary.component"
import { DatePipeTz } from "./dates/DateTz"

registerLocaleData(localeFr)

export function initOrder(config: VelOrderService) {
  return () => config.initOrder()
}

@NgModule({
  declarations: [
    AppComponent,
    TimeslotPageComponent,
    DatePageComponent,
    UserInformationPageComponent,
    PaymentPageComponent,
    DownloadPageComponent,
    HomePageComponent,
    VelLayoutComponent,
    ProductComponent,
    PassengersComponent,
    NextButtonComponent,
    LegalPageComponent,
    ConfidentialityPageComponent,
    MaintenancePageComponent,
    DatePipe2,
    DatePipeTz,
    TimeslotChoiceComponent,
    TimeslotChoiceComponent,
    DependenciesSummaryComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,

    StepsModule,
    ButtonModule,
    CalendarModule,
    TableModule,
    ToastModule,
    InputTextModule,
    DropdownModule,
    CheckboxModule,
    DialogModule,
    TooltipModule,
  ],
  providers: [
    MessageService,
    {
      provide: LOCALE_ID,
      useValue: "fr-FR",
    },
    { provide: DEFAULT_TIMEOUT, useValue: 10000 },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true,
    },
    {
      provide: "ClosedForMaintenance",
      useValue: () => {
        return true
      },
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initOrder, // calling initOrder before app launching
      deps: [VelOrderService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
