export interface UserModel {
  email: string
  first_name: string
  last_name: string
  phone?: string
  postal_code: string
  country?: string
  newsletter_accepted: boolean
}
export const MAIL_REGEXP = /[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,6}/

export enum DomainFormatErrorCode {
  EmailFormat = "emailFormat",
  LoginFormat = "loginFormat",
  LastNameFormat = "lastNameFormat",
  FirstNameFormat = "firstNameFormat",
  PasswordFormat = "passwordFormat",
  EmailMismatch = "emailMismatch",
  Required = "required",
  MaxLength = "maxlength",
}

export const DomainFormatErrorMessage = {
  [DomainFormatErrorCode.EmailFormat]: "Adresse email invalide",
  [DomainFormatErrorCode.LoginFormat]: "entre 3 et 15 caractères",
  [DomainFormatErrorCode.LastNameFormat]: "entre 2 et 30 caractères",
  [DomainFormatErrorCode.FirstNameFormat]: "entre 2 et 30 caractères",
  [DomainFormatErrorCode.PasswordFormat]: "entre 8 et 128 caractères",
  [DomainFormatErrorCode.EmailMismatch]: "Les 2 emails doivent être identiques",
  [DomainFormatErrorCode.Required]: "Ce champ est obligatoire",
  [DomainFormatErrorCode.MaxLength]: "Ce champ comporte trop de caractères",
}
