import { Component, OnInit } from "@angular/core"
import { VelOrderService } from "../../../services/vel-order.service"
import { VelApiService } from "../../../services/vel-api.service"

import { RateModel } from "../../../models/rate"
import { OrderLogic } from "../../../models/order.logic"

type OptionModel = { value: number; label: string }
type QuantityModel = {
  options: Array<OptionModel>
  filteredOptions: Array<OptionModel>
}

@Component({
  selector: "app-passengers",
  templateUrl: "./passengers.component.html",
  styleUrls: ["./passengers.component.scss"],
})
export class PassengersComponent implements OnInit {
  quantityChoices: Array<QuantityModel> = []
  prices: RateModel[] = []

  remainingSeats = 0
  totalPassengers = 0
  total = 0

  constructor(
    private velOrderService: VelOrderService,
    private velApiService: VelApiService
  ) {}

  async ngOnInit(): Promise<any> {
    if (!this.velOrderService.checkOrderConsistency(3)) {
      return
    }
    const timeslotDetails = await this.velApiService.getTimeslotDetails(
      this.velOrderService.getProductId(),
      this.velOrderService.getTimeslotId()
    )
    this.remainingSeats = OrderLogic.getRemainingSeat(timeslotDetails.remaining)
    const response = await this.velApiService.getPrices(
      this.velOrderService.getProductId(),
      this.velOrderService.getIndicativeDate()
    )
    this.prices = response.list
    if (this.velOrderService.getOrderPrices().length > 0) {
      this.velOrderService.getOrderPrices().forEach((choice) => {
        if (choice.quantity > 0) {
          const price = this.prices.find((x) => x.id === choice.price_id)
          if (price) {
            price.quantity = choice.quantity
          }
        }
      })
    }

    this.initQuantityChoices()
    this.updateViewAndSave()
  }

  next(): void {
    this.velOrderService.setRates(this.prices)
  }

  initQuantityChoices(): void {
    this.quantityChoices = this.prices.map((y) => {
      const array = new Array(this.remainingSeats + 1)
        .fill(0)
        .map((x, i) => ({ value: i, label: i + "" }))
      return {
        options: array,
        filteredOptions: array,
      }
    })
  }

  updateViewAndSave(): void {
    this.total = OrderLogic.getTotalPrice(this.prices)
    this.totalPassengers = OrderLogic.getTotalPassengers(this.prices)

    const boundaries = OrderLogic.getPriceQuantitiesBoundaries(
      this.prices,
      this.remainingSeats
    )
    this.quantityChoices.forEach((options, index) => {
      const boundary = boundaries[index]
      options.filteredOptions = options.options.filter(
        (x) =>
          (x.value <= boundary.max &&
            x.value >= boundary.min &&
            (x.value - boundary.min) % boundary.gap === 0) ||
          x.value === 0
      )
    })
  }
}
