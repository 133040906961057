<div class="container">
  <h3 [innerHTML]="title"></h3>
  <p-table [value]="slots" styleClass="p-datatable-sm" [loading]="loading">
    <ng-template pTemplate="header">
      <tr>
        <th class="th">Départ</th>
        <th class="th">Arrivée</th>
        <th class="th">Disponibilité</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-timeslot>
      <tr
        (click)="timeslot.remaining > 0 && selectSlot(timeslot)"
        [ngClass]="{
          selected: isSelected(timeslot.id),
          past: isPast(timeslot),
          full: timeslot.remaining <= 0,
          top: true
        }"
      >
        <td>
          {{ timeslot.start_at | dateTz : "HH:mm" }}
        </td>
        <td>
          {{ timeslot.end_at | dateTz : "HH:mm" }}
        </td>
        <td>
          {{
            timeslot.remaining > 0
              ? timeslot.remaining <= 30
                ? timeslot.remaining + " places disponibles"
                : "Places disponibles"
              : "Complet"
          }}
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr class="empty">
        <td [attr.colspan]="3">Aucun créneau</td>
      </tr>
    </ng-template>
  </p-table>
</div>
