import { Component, OnInit } from "@angular/core"

import { ActivatedRoute, Router } from "@angular/router"
import { VelOrderService } from "../../services/vel-order.service"
import { VelApiService } from "../../services/vel-api.service"
import { TimeSlotsModel } from "../../models/timeslots"
import { TimeslotChoicesInput } from "../../components/timeslot-choice/timeslot-choice.component"
import { DateTz } from "../../dates/DateTz"

@Component({
  selector: "app-timeslot-page",
  templateUrl: "./timeslot-page.component.html",
  styleUrls: ["./timeslot-page.component.scss"],
})
export class TimeslotPageComponent implements OnInit {
  hasMuseumStop = false
  date = new DateTz()
  popupDateVisible = false
  disablePreviousDay = false

  outwardsLoading = false
  backwardsLoading = false

  items: Array<TimeslotChoicesInput> = []
  loading = false

  title = ""
  slots: Array<TimeSlotsModel> = []
  dependencies: Array<TimeSlotsModel> = []

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private velApiService: VelApiService,
    private velOrderService: VelOrderService
  ) {}

  ngOnInit(): void {
    if (this.velOrderService.checkOrderConsistency(2)) {
      this.date = this.velOrderService.getIndicativeDate()
      this.hasMuseumStop = this.velOrderService.hasMuseumStop()
      this.updatePaths().then(() =>
        this.timeslotUpdate(this.velOrderService.getTimeslotId())
      )
    }
  }

  timeslotUpdate(slotId: string): void {
    const slot = this.slots.find((x) => x.id === slotId)
    this.dependencies = slot?.dependencies || []
  }

  updatePaths(): Promise<any> {
    this.outwardsLoading = true
    this.backwardsLoading = true
    const productId = this.velOrderService.getProductId()

    this.loading = true

    return this.velApiService
      .getTimeslots(productId, this.date)
      .then((slots) => {
        const filteredSlots = slots.list.filter(it =>{ 
          //@HACK pour ne pas afficher les mardis en VeL entre le 4 juin et le 07 juillet
          return !(
            it.start_at.isBetween(new Date("2023-06-04"), new Date("2023-07-07"))
            && it.start_at.isDayOfWeek(2)
          )
        })
        this.title = slots.list[0]?.label || ""
        this.slots = filteredSlots
        this.loading = false
      })
  }

  yesterday(): void {
    const yesterday = this.date.date.subtract(1, "day")
    const today = new DateTz().date
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0)
    if (yesterday.isBefore(today)) {
      return
    }
    this.updateDate(yesterday.toDate())
  }

  tomorrow(): void {
    const tomorrow = this.date.date.add(1, "day")
    this.updateDate(tomorrow.toDate())
  }

  updateDate(date: Date): void {
    this.date = new DateTz(date)
    this.velOrderService.setDate(this.date)
    this.updatePaths()
    this.popupDateVisible = false
    this.dependencies = []
    this.checkPreviousDay()
  }

  checkPreviousDay(): void {
    this.disablePreviousDay =
      this.date.format("dddd D MMMM YYYY") ===
      new DateTz().format("dddd D MMMM YYYY")
  }

  next(): void {
    this.velOrderService.validateSlots()
  }
}
