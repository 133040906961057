import { UntypedFormGroup } from "@angular/forms"
import { DomainFormatErrorMessage } from "../models/user.model"

export function showFormErrors(fg: UntypedFormGroup): void {
  fg.markAsDirty()
  fg.updateValueAndValidity()
  const fg1 = fg as any
  fg1._forEachChild((control: any) => {
    if (control instanceof UntypedFormGroup) {
      showFormErrors(control)
    } else {
      control.markAsDirty()
      control.updateValueAndValidity()
    }
  })
}

export function formatErrorMessage(
  error: { [key: string]: string } | null
): string {
  if (!error) {
    return "\u00a0"
  }
  const key = error.required ? "required" : Object.keys(error)[0]
  return (DomainFormatErrorMessage as any)[key] || key
}
