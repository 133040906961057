import { Component, OnInit } from "@angular/core"
import { VelApiService } from "../../services/vel-api.service"
import { ProductModel } from "../../models/product"

@Component({
  selector: "app-home-page",
  templateUrl: "./home-page.component.html",
  styleUrls: ["./home-page.component.scss"],
})
export class HomePageComponent implements OnInit {
  products: ProductModel[] = []

  constructor(private velApiService: VelApiService) {
    this.velApiService
      .getProducts()
      .then((products) => (this.products = products.list))
  }

  ngOnInit(): void {}
}
