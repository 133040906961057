import { Component, OnDestroy, OnInit } from "@angular/core"
import pi18nFr from "./fr-i18n"
import { PrimeNGConfig } from "primeng/api"
import { Subscription } from "rxjs"
import { NavigationEnd, Router } from "@angular/router"
import { VelOrderService } from "./services/vel-order.service"
import {
  routeIsPaymentCompleted,
  routeIsPaymentRefused,
} from "./routing-helper"

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  title = "Vente en ligne - Train de la Mure"
  subs = new Subscription()
  class = ""

  constructor(
    private config: PrimeNGConfig,
    private router: Router,
    public velOrderService: VelOrderService
  ) {}

  ngOnInit(): void {
    this.config.setTranslation(pi18nFr)

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const url = event.urlAfterRedirects
        this.class = url.replace(/\//g, "-").slice(1).split("?")[0]
        if (routeIsPaymentCompleted(url)) {
          this.class = "vel-payment-completed"
        }
        if (routeIsPaymentRefused(url)) {
          this.class = "vel-payment-refused"
        }
        window.scrollTo(0, 0)
      }
    })
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe()
  }
}
