import { TimeSlotsModel } from "./timeslots"
import { DateTz } from "../dates/DateTz"

export class TimeslotsFactory {
  static wrapper(x: any): TimeSlotsModel {
    x.start_at = new DateTz(x.start_at)
    x.end_at = new DateTz(x.end_at)
    x.dependencies = x.dependencies.map((y: any) => {
      y.end_at = new DateTz(y.end_at)
      y.start_at = new DateTz(y.start_at)
      return y
    })
    return x
  }
}
