import { Injectable } from "@angular/core"
import { NavigationEnd, Router } from "@angular/router"
import { MessageService } from "primeng/api"
import { RateModel } from "../models/rate"
import { RowCommand, VelApiService } from "./vel-api.service"
import { ProductModel } from "../models/product"
import { OrderModel, OrderSessionModel } from "../models/order"
import { UserModel } from "../models/user.model"
import { TimeSlotsModel } from "../models/timeslots"
import { DateTz } from "../dates/DateTz"

const SESSION_ORDER_KEY = "session_order_vel"

@Injectable({
  providedIn: "root",
})
export class VelOrderService {
  currentUrl = ""
  public stepperItems = [
    {
      label: "Voyage",
      routerLink: "../voyages",
      disabled: true,
    },
    {
      label: "Date",
      routerLink: "date",
      title: "Sélectionnez la date",
      disabled: true,
    },
    {
      label: "Horaires",
      routerLink: "horaires",
      title: "Sélectionnez les horaires",
      disabled: true,
    },
    {
      label: "Voyageurs",
      title: "Sélectionnez le nombre de voyageurs",
      routerLink: "voyageurs",
      disabled: true,
    },
    {
      label: "Coordonnées",
      title: "Coordonnées",
      routerLink: "contact",
      disabled: true,
    },
    {
      label: "Paiement",
      routerLink: "paiement",
      title: "",
      disabled: true,
    },
  ]

  sessionOrder: OrderSessionModel = this._newSessionOrder()

  constructor(
    private router: Router,
    private velApiService: VelApiService,
    private messageService: MessageService
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const url = event.urlAfterRedirects.slice(1).split("?")[0]
        this.currentUrl = url
        this.enableSteps()
      }
    })
  }

  private _newSessionOrder(): OrderSessionModel {
    return {
      row_id: "",
      order_id: "",
      product_id: "",
      product_title: "",
      product_bg: "",
      timeslot_id: "",
      indicative_date: new DateTz(),
      prices: [],
      customer: {
        email: "",
        first_name: "",
        last_name: "",
        phone: "",
        postal_code: "",
        country: "",
        newsletter_accepted: false,
      },
    }
  }

  savingSessionOrder(): void {
    sessionStorage.setItem(SESSION_ORDER_KEY, JSON.stringify(this.sessionOrder))
  }

  initOrder(): void {
    this.sessionOrder = this._newSessionOrder()

    const reviver = (key: string, value: any) => {
      if (value.hasOwnProperty("date") && value.hasOwnProperty("timezone")) {
        return new DateTz(value.date, value.timezone)
      }
      return value
    }

    try {
      this.sessionOrder = JSON.parse(
        sessionStorage.getItem(SESSION_ORDER_KEY) || "",
        reviver
      )
    } catch (ex) {
      console.error(
        "JSON.parse(sessionStorage.getItem(SESSION_ORDER_KEY)) not possible"
      )
      sessionStorage.clear()
      this.router.navigateByUrl("/")
    }
  }

  fetchOrder(orderId: string, showError = true): Promise<OrderModel> {
    return this.velApiService.getOrder(orderId, showError).catch(() => {
      this.resetOrder()
      this.router.navigateByUrl("/voyages")
      this.messageService.add({
        severity: "error",
        summary: "Erreur",
        detail: "La commande n'existe pas",
      })
      return {} as OrderModel
    })
  }

  getOrderPrices(): Array<{ price_id: string; quantity: number }> {
    return this.sessionOrder.prices
  }

  getTimeslotId(): string {
    return this.sessionOrder.timeslot_id
  }

  getIndicativeDate(): DateTz {
    return this.sessionOrder.indicative_date
  }

  getProductTitle(): string {
    return this.sessionOrder.product_title
  }

  getProductId(): string {
    return this.sessionOrder.product_id
  }

  getCustomer(): UserModel {
    return this.sessionOrder.customer
  }

  getImageBackground(): string {
    return this.sessionOrder.product_bg
  }

  hasMuseumStop(): boolean {
    return this.sessionOrder.product_title.includes("musée") /*FIXME Alban*/
  }

  updateRowOrder(params: RowCommand): Promise<OrderModel> {
    const rowId = this.sessionOrder.row_id
    const orderId = this.sessionOrder.order_id
    return (
      rowId
        ? this.velApiService.updateRowOrder(params, orderId, rowId)
        : this.velApiService.createRowOrder(params, orderId)
    ).then(() => this.fetchOrder(orderId))
  }

  confirmOrderRow(): Promise<any> {
    const rowId = this.sessionOrder.row_id
    const orderId = this.sessionOrder.order_id
    return this.velApiService.confirmOrderRow(orderId, rowId).catch((err) => {
      this.messageService.add({
        severity: "error",
        summary:
          "Un problème est survenu pendant le traitement de votre commande",
        detail: err,
        life: 8000,
      })
      this.velApiService.cancelOrder(orderId)
      this.resetOrder()
      this.router.navigateByUrl("/voyages")
      throw new Error("Impossible de confirmer la commande")
    })
  }

  setProduct(product: ProductModel): void {
    if (product.id === this.sessionOrder.product_id) {
      this.router.navigateByUrl("/commande/date")
      return
    }
    this.sessionOrder.timeslot_id = ""
    this.sessionOrder.prices = []
    this.sessionOrder.indicative_date = new DateTz()

    if (product.availability_start_at.date.isAfter(new DateTz().date)) {
      this.sessionOrder.indicative_date = product.availability_start_at
    }
    this.sessionOrder.product_id = product.id
    this.sessionOrder.product_title = product.title
    this.sessionOrder.product_bg = product.images.large
    this.savingSessionOrder()
    this.router.navigateByUrl("/commande/date")
  }

  setDate(date: DateTz): void {
    if (
      date.toApiFormat() === this.sessionOrder.indicative_date.toApiFormat()
    ) {
      this.router.navigateByUrl("/commande/horaires")
      return
    }
    this.sessionOrder.indicative_date = date
    this.savingSessionOrder()
    this.router.navigateByUrl("/commande/horaires")
  }

  isTimeSlotSelected(id: string): boolean {
    return this.getTimeslotId() === id
  }

  setTimeSlots(slotId: string): void {
    this.sessionOrder.timeslot_id = slotId
    this.savingSessionOrder()
  }

  validateSlots(): void {
    const timeslotId = this.getTimeslotId()
    if (!timeslotId || timeslotId === "null") {
      this.showError("Merci de sélectionner un créneau.")
    } else {
      this.router.navigateByUrl("/commande/voyageurs")
    }
  }

  setRates(rates: RateModel[]): void {
    if (rates.filter((x) => x.quantity > 0).length === 0) {
      return this.showError("Au moins un voyageur est nécessaire.")
    }

    let error = ""
    rates.forEach((rate) => {
      if (rate.quantity > 0 && rate.requirements.length > 0) {
        const requirementsMatchingCount = rate.requirements.filter(
          (requirement) => {
            const dependencyPrice = rates.find(
              (price2) => requirement.rate_id === price2.rate_id
            )
            return (dependencyPrice?.quantity || 0) > 0
          }
        ).length
        if (requirementsMatchingCount === 0) {
          error =
            "Certain(s) tarif(s) sélectionné(s) ne peuvent pas être vendus seuls"
        }
      }
    })
    if (error) {
      return this.showError(error)
    }

    this.sessionOrder.prices = rates
      .filter((x) => x.quantity > 0)
      .map((x) => ({
        price_id: x.id,
        quantity: x.quantity,
      }))
    this.savingSessionOrder()
    this.router.navigateByUrl("/commande/contact")
  }

  async setUserInfo(user: UserModel): Promise<void> {
    let order
    if (this.sessionOrder.order_id) {
      try {
        order = await this.velApiService.getOrder(this.sessionOrder.order_id)
      } catch (e) {}
    }
    if (!order?.id || order.status !== "Draft") {
      order = await this.velApiService.createNewOrder()
      this.sessionOrder.row_id = ""
    }
    this.sessionOrder.order_id = order.id
    this.sessionOrder.customer = user
    this.savingSessionOrder()

    order = await this.updateRowOrder({
      timeslot_id: this.getTimeslotId(),
      product_id: this.getProductId(),
      prices: this.sessionOrder.prices.filter((x) => x.quantity > 0),
    })
    this.sessionOrder.row_id = order.items[0]?.id
    this.savingSessionOrder()
    await this.velApiService.setInfoCustomer(user, order.id)
    await this.confirmOrderRow()

    this.router.navigateByUrl("/commande/paiement")
  }

  cancelOrder(order: OrderModel): Promise<any> {
    return this.velApiService.cancelOrder(order.id)
  }

  makePayment(order: OrderModel): Promise<any> {
    return this.velApiService
      .makePayment(
        `${window.location.origin}/commande/confirmation-du-paiment`,
        order.id
      )
      .then((x) => ((document as any).location.href = x.payment_link_url))
  }

  getTimeslotDependencies(order: OrderModel): Promise<TimeSlotsModel> {
    const item = order?.items[0]
    if (!item) {
      throw new Error("Impossible to get timeslot Dependencies")
    }
    return this.velApiService.getTimeslotDetails(
      item.product_id,
      item.timeslot_id
    )
  }

  resetOrder(): void {
    sessionStorage.clear()
    this.initOrder()
  }

  checkOrderConsistency(stepNumber: number): boolean {
    const checks = [
      () => true,
      () => !!this.sessionOrder?.product_id,
      () => !!this.sessionOrder?.indicative_date,
      () => !!this.sessionOrder?.timeslot_id,
      () => this.sessionOrder?.prices.length > 0,
      () =>
        this.sessionOrder?.customer.email &&
        this.sessionOrder?.customer.postal_code,
    ]

    let consistent = true
    for (let i = 1; i <= stepNumber; i++) {
      if (!checks[i]()) {
        consistent = false
        break
      }
    }

    if (!consistent) {
      this.router.navigateByUrl("/voyages")
      console.error("Commande / route incohérentes !", stepNumber)
    }
    return consistent
  }

  private enableSteps(): void {
    const currentRoute = this.currentUrl.replace("commande/", "")
    let disabled = false
    this.stepperItems.forEach((step) => {
      step.disabled = disabled
      if (step.routerLink.endsWith(currentRoute)) {
        disabled = true
      }
    })
  }

  showError(message: string): void {
    this.messageService.add({
      severity: "error",
      summary: "Erreur",
      detail: message,
    })
  }
}
