<article class="">
  <header></header>
  <div class="page-body">
    
    <p class="" style="text-align: center;font-size:1.4rem; color:#464d53; font-weight:300;">
      <i style="font-size:4rem;color:#464d53" class="pi pi-exclamation-circle"></i>
      <br><br>
      La billetterie en ligne du Petit Train de la Mure <br>est temporairement
      fermée.</p>

      <p class="" style="text-align: center;font-size:1.2rem; color:#464d53; font-weight:300;">
      Notre guichet sur place continue cependant à vous accueillir <br>pour vos
      achats et vos questions.
    </p>
  </div>
</article>
