import { Component, OnInit } from "@angular/core"
import { VelOrderService } from "../../services/vel-order.service"
import { ActivatedRoute, Router } from "@angular/router"
import { VelApiService } from "../../services/vel-api.service"
import { MessageService } from "primeng/api"
// @ts-ignore
import printJS from "print-js"

@Component({
  selector: "app-download-page",
  templateUrl: "./download-page.component.html",
  styleUrls: ["./download-page.component.scss"],
})
export class DownloadPageComponent implements OnInit {
  ticketsUrl = ""
  orderId = ""
  commandNumber = "XXXXXX"
  email = ""
  hasMuseumStop = false

  constructor(
    private velOrderService: VelOrderService,
    private velApiService: VelApiService,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private router: Router
  ) {
    this.hasMuseumStop = this.velOrderService.hasMuseumStop()
  }

  ngOnInit(): any {
    const orderId = this.route.snapshot.queryParams.order || ""
    const statusOK = this.route.snapshot.queryParams.status === "completed"

    if (!statusOK) {
      return this.router.navigateByUrl(`/commande/${orderId}/refus-du-paiment`)
    }

    if (orderId) {
      sessionStorage.clear() // the current order needs to be cleared to avoid "CannotBeEdited" errors when pressing back (browser)
      this.velApiService.getDocuments(orderId).then((docs) => {
        const pdf = docs.find((x) => x.type === "Ticket")
        this.ticketsUrl = pdf?.download_url || ""
      })

      this.velOrderService.fetchOrder(orderId, false).then((order) => {
        this.orderId = orderId
        this.commandNumber = order.number
        this.email = order.customer.email
      })
    }
  }

  next(): void {
    this.velOrderService.resetOrder()
    this.router.navigateByUrl("/voyages")
  }

  print(url: string): void {
    printJS({
      printable: url,
      type: "pdf",
      showModal: true,
      modalMessage: "Génération des billets...",
    })
  }
}
