import "dayjs/locale/fr"
import * as dayJS from "dayjs"
import * as utc from "dayjs/plugin/utc"
import * as plugin_timezone from "dayjs/plugin/timezone"
import { Pipe, PipeTransform } from "@angular/core"

dayJS.extend(utc)
dayJS.extend(plugin_timezone)
dayJS.locale("fr")

export class DateTz {
  private timezone = ""
  date = dayJS.tz(new Date())

  constructor(date: Date | string = new Date(), timezone = "Europe/Paris") {
    this.timezone = timezone
    this.setDate(date)
  }

  setDate(date: Date | string): void {
    this.date = dayJS.tz(new Date(date), this.timezone)
  }

  format(template: string): string {
    return this.date.format(template)
  }

  toDate(): Date {
    return this.date.toDate()
  }

  toApiFormat(): string {
    return this.date.format("YYYY-MM-DD")
  }

  isBetween(start:Date, end:Date): Boolean{
    return this.date.toDate() >= start &&  this.date.toDate() <= end
  }

  isDayOfWeek(dayOfWeek:number): Boolean{
    return this.date.day() === (dayOfWeek % 7)
  }
}

@Pipe({
  name: "dateTz",
})
export class DatePipeTz implements PipeTransform {
  transform(value: DateTz, format: string): string | null {
    return value.date.format(format)
  }
}
