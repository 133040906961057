<p-calendar
  [(ngModel)]="date"
  [inline]="true"
  (onMonthChange)="updateUnavailableDates($event)"
  [disabledDates]="closingDates"
  [firstDayOfWeek]="1"
  [ngClass]="{ disablePreviousMonth: disablePreviousMonth }"
  (onSelect)="dateChanged($event)"
  [minDate]="minDate"
>
  <ng-template pTemplate="date" let-date>
    <span [ngClass]="{ full: dateIsFull(date) }">{{ date?.day }}</span>
  </ng-template>
</p-calendar>

<div class="date-page-info"></div>
