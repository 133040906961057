<div class="btn">
  <button
    pButton
    pRipple
    type="button"
    [loading]="loading"
    [label]="label"
    (click)="onClick()"
    icon="pi pi-arrow-right"
    iconPos="right"
    class="large"
  ></button>
</div>
