import { NgModule } from "@angular/core"
import { RouterModule, Routes } from "@angular/router"
import { DatePageComponent } from "./pages/1-date-page/date-page.component"

import { DownloadPageComponent } from "./pages/6-download-page/download-page.component"
import { PaymentPageComponent } from "./pages/5-payment-page/payment-page.component"
import { VelLayoutComponent } from "./components/vel-layout/vel-layout.component"
import { HomePageComponent } from "./pages/0-home-page/home-page.component"
import { UserInformationPageComponent } from "./pages/4-user-information-page/user-information-page.component"
import { PassengersComponent } from "./pages/3-passengers-page/passengers/passengers.component"
import { LegalPageComponent } from "./pages/legal-page/legal-page.component"
import { ConfidentialityPageComponent } from "./pages/confidentiality-page/confidentiality-page.component"
import { MaintenancePageComponent } from "./pages/maintenance/maintenance-page.component"
import { NewSeasonPageComponent } from "./pages/new-season-page/new-season-page.component"
import { TimeslotPageComponent } from "./pages/2-timeslot-page/timeslot-page.component"

const routes: Routes = [
  {
    path: "maintenance",
    pathMatch: "full",
    component: MaintenancePageComponent,
  },

  //{path: "**", pathMatch: "full", redirectTo: "/maintenance"},

  /* NEW SEASON ROUTING
  {path: "**", pathMatch: "full", redirectTo: "/voyages"},
  {
    path: "**",
    pathMatch: "full",
    component: NewSeasonPageComponent
  },
  /* END NEW SEASON ROUTING */

  {
    path: "voyages",
    component: HomePageComponent,
  },
  {
    path: "legal",
    component: LegalPageComponent,
  },
  {
    path: "confidentiality",
    component: ConfidentialityPageComponent,
  },
  {
    path: "commande",
    component: VelLayoutComponent,
    children: [
      {
        path: "date",
        component: DatePageComponent,
      },
      {
        path: "horaires",
        component: TimeslotPageComponent,
      },
      {
        path: "contact",
        component: UserInformationPageComponent,
      },
      {
        path: "voyageurs",
        component: PassengersComponent,
      },
      {
        path: "confirmation-du-paiment",
        component: DownloadPageComponent,
      },
      {
        path: ":orderId/refus-du-paiment",
        component: PaymentPageComponent,
      },
      {
        path: "paiement",
        component: PaymentPageComponent,
      },
      { path: "**", pathMatch: "full", redirectTo: "/commande/date" },
    ],
  },
  { path: "**", pathMatch: "full", redirectTo: "/voyages" },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
