export function routeIsPaymentRefused(url: string): boolean {
  return url.includes("/refus-du-paiment")
}

export function routeIsPaymentCompleted(url: string): boolean {
  return url.includes("/confirmation-du-paiment")
}

export function routeIsPaymentRedirect(url: string): boolean {
  return routeIsPaymentRefused(url) || routeIsPaymentCompleted(url)
}
