import { RateModel } from "./rate"
import { OrderModel } from "./order"

export type QuantitiesBoundaries = Array<{
  min: number
  max: number
  gap: number
}>

const MAXIMUM_PASSENGERS_PER_ORDER = 19

export class OrderLogic {
  static getTotalPrice(prices: RateModel[]): number {
    return (
      prices.reduce((acc, value) => {
        return acc + (value.quantity || 0) * value.net_price.amount
      }, 0) / 100
    )
  }

  static getRemainingSeat(availableSeats: number): number {
    return Math.min(availableSeats, MAXIMUM_PASSENGERS_PER_ORDER)
  }

  static getTotalPassengers(prices: RateModel[] = []): number {
    return prices.reduce((acc, value) => {
      return acc + (value.gauge_aware ? value.quantity || 0 : 0)
    }, 0)
  }

  static getPriceQuantitiesBoundaries(
    rates: RateModel[],
    remainingPlace: number
  ): QuantitiesBoundaries {
    const totalRemainingSeat = OrderLogic.getRemainingSeat(remainingPlace)
    const totalPassenger = OrderLogic.getTotalPassengers(rates)
    const remainingSeat = totalRemainingSeat - totalPassenger

    return rates.map((price) => ({
      max: Math.min(
        price.max || Infinity,
        price.gauge_aware
          ? (price.quantity || 0) + remainingSeat
          : totalRemainingSeat
      ),
      min: price.min || 0,
      gap: price.gap || 1,
    }))
  }
}
