<form [formGroup]="fg">
  <div class="container">
    <div class="text">Champs obligatoires</div>
    <div class="flex">
      <div>
        <input
          type="text"
          pInputText
          formControlName="email"
          required
          placeholder="Adresse email *"
        />
        <ng-container *ngIf="fg.get('email') as email">
          <div
            class="input-errors"
            [style.visibility]="
              email.dirty || email.touched ? 'visible' : 'hidden'
            "
          >
            {{ formatErrorMessage(email.errors) }}
          </div>
        </ng-container>
      </div>
      <div>
        <input
          type="text"
          pInputText
          formControlName="email2"
          required
          placeholder="Confirmer l'adresse email"
        />
        <ng-container *ngIf="fg.get('email2') as email">
          <div
            class="input-errors"
            [style.visibility]="
              email.dirty || email.touched ? 'visible' : 'hidden'
            "
          >
            {{ formatErrorMessage(email.errors) }}
          </div>
        </ng-container>
      </div>
    </div>

    <input
      type="text"
      pInputText
      formControlName="postal_code"
      required
      placeholder="Code postal *"
    />
    <ng-container *ngIf="fg.get('postal_code') as zipcode">
      <div
        class="input-errors"
        [style.visibility]="
          zipcode.dirty || zipcode.touched ? 'visible' : 'hidden'
        "
      >
        {{ formatErrorMessage(zipcode.errors) }}
      </div>
    </ng-container>

    <div class="text">Champs facultatifs</div>
    <div class="flex optional">
      <input
        type="text"
        pInputText
        formControlName="last_name"
        placeholder="Nom"
      />
      <input
        type="text"
        pInputText
        formControlName="first_name"
        placeholder="Prénom"
      />
    </div>

    <div class="mentions">
      <div class="p-field-checkbox">
        <p-checkbox
          [binary]="true"
          inputId="newsletter"
          formControlName="newsletter_accepted"
        ></p-checkbox>
        <label for="newsletter"
          >J'accepte de recevoir les actualités du Petit Train de la
          Mure.</label
        >
      </div>
      <div class="rgpd">
        * Votre adresse mail sera utilisée par le Train de la Mure pour vous
        envoyer votre récapitulatif de commande, vos billets électroniques et
        pour vous contacter en cas de modifications sur vos trajets.
      </div>
    </div>
  </div>
</form>
<app-next-button
  (click2)="next()"
  label="Paiement"
  [loading]="loading"
></app-next-button>
