import { Component, EventEmitter, OnInit, Output } from "@angular/core"
import { Router } from "@angular/router"
import { VelOrderService } from "../../services/vel-order.service"
import { VelApiService } from "../../services/vel-api.service"
import { DateTz } from "../../dates/DateTz"

@Component({
  selector: "app-date-page",
  templateUrl: "./date-page.component.html",
  styleUrls: ["./date-page.component.scss"],
})
export class DatePageComponent implements OnInit {
  @Output() dateUpdated = new EventEmitter()
  minDate = new Date()
  date: Date | null = null
  closingDates: Date[] = []
  fullDates: DateTz[] = []
  disablePreviousMonth = false

  constructor(
    private router: Router,
    private velApiService: VelApiService,
    private velOrderService: VelOrderService
  ) {}

  ngOnInit(): void {
    if (this.velOrderService.checkOrderConsistency(1)) {
      this.date = this.velOrderService.getIndicativeDate().toDate()
      this.updateUnavailableDates({
        month: this.date.getMonth() + 1,
        year: this.date.getFullYear(),
      })
    }
  }

  updateUnavailableDates(event: { month: number; year: number }): void {
    this.checkPreviousMonth(event)
    this.closingDates = this.getDaysInMonth(event.month, event.year)
    this.velApiService
      .getUnavailableDateByMonth(
        event.month,
        event.year,
        this.velOrderService.getProductId()
      )
      .then((response) => {
        const days = response.list.filter((it) =>{
          //@HACK pour ne pas afficher les mardis en VeL entre le 4 juin et le 07 juillet
          return !(
            it.date.isBetween(new Date("2023-06-04"), new Date("2023-07-07"))
            && it.date.isDayOfWeek(2)
          )
        })
        this.fullDates = days
          .filter((x) => x.status === "SoldOut")
          .map((x) => x.date)
        const openingDays = days
          .filter((x) => x.status === "Open")
          .map((x) => x.date)
        this.closingDates = this.closingDates.filter(
          (x) =>
            !openingDays.find(
              (y) => new DateTz(x).toApiFormat() === y.toApiFormat()
            )
        )
      })
  }

  dateChanged(date: Date): void {
    this.velOrderService.setDate(new DateTz(date))
    this.dateUpdated.emit(date)
  }

  dateIsFull(date: { year: number; month: number; day: number }): boolean {
    const dateStr = new Date(date.year, date.month, date.day).toDateString()
    return !!this.fullDates.find((x) => x.toDate().toDateString() === dateStr)
  }

  checkPreviousMonth(event: { month: number; year: number }): void {
    const now = new DateTz().toDate()
    this.disablePreviousMonth =
      now.getFullYear() === event.year && now.getMonth() === event.month - 1
  }

  getDaysInMonth(month: number, year: number): Date[] {
    const date = new Date(year, month - 1, 1)
    const days = []
    while (date.getMonth() === month - 1) {
      days.push(new Date(date))
      date.setDate(date.getDate() + 1)
    }
    return days
  }
}
