<div class="container">
  <div
    class="image"
    [style.background-image]="'url(' + model.images.thumbnail + ')'"
  ></div>
  <div class="text-container">
    <h4>{{ model.title }}</h4>
    <p class="description" [innerHTML]="model.description"></p>
  </div>
  <div class="fake-footer"></div>
  <div class="footer">
    <div>
      <button
        pButton
        pRipple
        type="button"
        label="Je réserve"
        (click)="chooseProduct()"
        icon="pi pi-arrow-right"
        iconPos="right"
        class="large"
      ></button>
    </div>
    <div class="label-price">
      A partir de {{ model.indicative_price.display }}
    </div>
  </div>
</div>
