import { Component, Input, OnInit } from "@angular/core"
import { ProductFactory } from "../../models/product.factory"

import { VelOrderService } from "../../services/vel-order.service"
import { ProductModel } from "../../models/product"

@Component({
  selector: "app-product",
  templateUrl: "./product.component.html",
  styleUrls: ["./product.component.scss"],
})
export class ProductComponent implements OnInit {
  @Input() model: ProductModel = ProductFactory.getEmtpy()

  constructor(private velOrderService: VelOrderService) {}

  ngOnInit(): void {}

  chooseProduct(): void {
    this.velOrderService.setProduct(this.model)
  }
}
