import { ProductModel } from "./product"
import { DateTz } from "../dates/DateTz"

export class ProductFactory {
  static wrapper(response: any): ProductModel {
    response.availability_start_at = new DateTz(response.availability_start_at)
    response.availability_end_at = new DateTz(response.availability_end_at)
    return response
  }

  static getEmtpy(): ProductModel {
    return {
      "@kind": "",
      id: "",
      title: "",
      description: "",
      images: {
        thumbnail: "",
        large: "",
      },
      indicative_price: {
        amount: 0,
        display: "",
        currency: "",
      },
      availability_end_at: new DateTz(),
      availability_start_at: new DateTz(),
    }
  }
}
