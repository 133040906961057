<article id="0a9f2cde-460b-4b66-b826-f2843c6b6275" class="page sans">
  <header><h1 class="page-title"></h1></header>
  <div class="page-body">
    <p id="89e0011d-0469-4860-8859-d121d7c838df" class=""></p>
    <p class=""><em>Date de la dernière modification : 15 avril 2021</em></p>
    <p id="54fcb996-1f7d-444a-94ce-ede147d96152" class=""></p>
    <h3 class=""><strong>Introduction</strong></h3>
    <p id="678034ac-5d0d-44b9-bbc8-a81c6074b675" class="">
      La présente politique de protection des données personnelles définit nos
      mesures concernant les informations que nous recueillons à votre sujet
      directement ou indirectement (ci-après les « données personnelles »),
      l’utilisation que nous en faisons et à qui nous les communiquons. Elle
      définit également vos droits à l’égard de vos données personnelles et qui
      vous pouvez contacter pour plus d’informations ou pour exercer vos droits.
    </p>
    <p id="91d292e9-e2a1-44c0-af96-77ada1459a66" class=""></p>
    <h3 class="">
      <strong
        >Contenu de la politique de protection des données personnelles</strong
      >
    </h3>
    <p id="6c7da5a3-6353-46ef-8302-74f939dfcc1e" class="">
      Cette politique de protection des données personnelles s’applique à EDEIS
      S.A.S. [<a href="https://lepetittraindelamure.com/mentions-legales/"
        >https://lepetittraindelamure.com/mentions-legales/</a
      >]<strong> </strong>en tant que Responsable de Traitement et éditrice du
      site https://lepetittraindelamure.com, ainsi qu’à Logick S.A.S.
      <strong>[</strong
      ><a href="https://billetterie.lepetittraindelamure.com/legal"
        >https://billetterie.lepetittraindelamure.com/legal</a
      ><strong>]</strong> en tant que Sous-Traitant et éditrice du site
      <a href="https://billetterie.lepetittraindelamure.com"
        >https://billetterie.lepetittraindelamure.com</a
      >.
    </p>
    <p id="9bb761b8-2f15-4d02-9b03-410a91771125" class="">
      Nous nous engageons à mettre en œuvre les mesures appropriées pour
      protéger vos données personnelles et les traiter de manière licite, loyale
      et transparente.
    </p>
    <p id="8edf8939-545b-49df-b333-23e060b18dff" class="">
      Cette politique de protection des données personnelles fait état de la
      façon dont nous les collectons, traitons, stockons et protégeons lorsque :
    </p>
    <ul id="339d14a7-b024-4ea6-a2b2-e97838ad82b1" class="bulleted-list">
      <li>
        nous vous fournissons des Services liés au Petit Train de la Mure, ou
        des prestations liées au Musée La Mine Image ou lorsque nous en
        fournissons à nos clients, ou utilisateurs de nos sites Web, visiteurs
        ou voyageurs ;
      </li>
    </ul>
    <ul id="cdaa9d0c-d6e9-485b-9b4b-45b2820a3aab" class="bulleted-list">
      <li>vous accédez ou vous utilisez nos sites Web ;</li>
    </ul>
    <ul id="d9aad756-cb9e-4d8f-a347-6a8da6c64094" class="bulleted-list">
      <li>
        il est effectué toute autre opération relevant de nos offres ou
        propositions en termes de Services ou prestations.
      </li>
    </ul>
    <p id="936bb185-8193-40c7-a8c0-b0ab20ddbb20" class="">
      Lorsque nous faisons référence à « nos sites Web » ou « ces sites Web »
      dans le cadre des présentes, nous entendons faire référence aux pages
      spécifiques de chacun des sites accessibles par les liens suivants :
      <a href="https://lepetittraindelamure.com/"
        >https://lepetittraindelamure.com</a
      >
      ou selon le cas,
      <a href="https://billetterie.lepetittraindelamure.com"
        >https://billetterie.lepetittraindelamure.com</a
      >
    </p>
    <p id="92c4af64-4a5a-4076-bbc0-7b1d7b424dd2" class="">
      Cette politique de protection des données personnelles contient en
      particulier des informations concernant la communication de vos données
      personnelles entre EDEIS S.A.S. et Logick S.A.S. pour le Service de
      billetterie.
    </p>
    <p id="0acf0e68-1fd9-45c1-8dc6-9a11a27e560e" class=""></p>
    <h3 class=""><strong>À propos des noms de domaines</strong></h3>
    <p id="004d5bc8-3491-41b8-b5fd-190c73658be0" class="">
      Le nom de domaine « lepetittraindelamure.com » permet d’avoir accès à
      différents Services ou prestations, dont le service de billetterie
      accessible par le nom de domaine « billetterie.lepetittraindelamure.com ».
    </p>
    <p id="bf092a14-c351-49f4-99d3-a4d251b92129" class="">
      Nous vous informons que ces sites Web sont régis par la présente politique
      de protection des données personnelles.
    </p>
    <p id="ecc70f24-9a27-4db4-a2f3-4ff66a897ebd" class="">
      Nous invitons les visiteurs-internautes à prendre connaissance de cette
      politique de protection des données personnelles commune à chacun de ces
      sites Web avant de communiquer toute donnée personnelle.
    </p>
    <p id="87913c72-7420-4909-9166-8ddc79d6d5b3" class=""></p>
    <h3 class="">
      <strong>Les données personnelles que nous collectons</strong>
    </h3>
    <p id="d66a36c8-e502-401d-b44d-301466beac85" class="">
      Dans le cadre des Services et prestations que nous vous fournissons ou
      lorsque nous en fournissons à nos clients et en lien avec les Services du
      Petit Train de la Mure, ainsi que les prestations du Musée La Mine Image,
      nous pouvons être amenés à solliciter ou faire solliciter la collecte et
      obtenir des données personnelles vous concernant.
    </p>
    <p id="d8943d7f-f8ab-40c2-81d3-083a11a2b9e1" class="">
      Nous pouvons également collecter vos données personnelles lorsque vous
      utilisez les sites Web : (i) du Petit Train de la Mure, ou selon le cas
      (ii) du service de billetterie du Petit Train de la Mure.
    </p>
    <p id="b79ffd06-ebaa-4103-82b0-aa74d3b54c17" class="">
      Nous pouvons ainsi être amenés à collecter et obtenir des données
      personnelles parce que vous nous les communiquez (par exemple dans le
      cadre d’un formulaire sur les sites Web précités), ou bien et selon le
      cas, parce que d&#x27;autres personnes nous les fournissent (par exemple,
      des fournisseurs de services que nous utilisons pour les besoins de nos
      activités) ou parce qu&#x27;il s’agit de données accessibles et
      disponibles dans le domaine public.
    </p>
    <p id="0fa215df-de39-42c7-adba-ab6ca226a4d9" class="">
      Nous pouvons également être amenés à collecter et obtenir directement ou
      indirectement des données personnelles vous concernant, à la suite de
      l’analyse de votre comportement lorsque vous interagissez avec nous ou
      d&#x27;autres personnes. Par exemple, afin d’améliorer votre expérience en
      qualité de visiteur lorsque vous utilisez les sites Web précités et vous
      assurer un bon fonctionnement de Services Web, nous pouvons (ou selon le
      cas, nos fournisseurs de services) utiliser des cookies (petits fichiers
      texte stockés dans le navigateur d’un utilisateur de Services Web) ainsi
      que des balises Web susceptibles de collecter des données personnelles. En
      cas d&#x27;utilisation de cookies, des informations et renseignements
      complémentaires sur la façon dont nous utilisons les cookies ainsi que
      d&#x27;autres traceurs et la manière dont vous pouvez les contrôler seront
      disponibles dans notre charte sur les cookies<strong>.</strong>
    </p>
    <p id="9b021115-9e33-4c2c-ba8d-8a0199247b17" class="">
      Les données personnelles que nous collectons ou obtenons directement ou
      indirectement, soit lorsque nous vous fournissons nos Services ou
      prestations ou lorsque nous en fournissons à nos clients, soit lorsque
      vous utilisez les sites Web précités, soit plus généralement lors de
      toutes autres opérations susceptibles de relever de nos offres en termes
      de Services ou prestations, peuvent inclure, notamment, votre nom, votre
      prénom, votre adresse courriel, votre code postal, votre adresse IP, le
      type et la langue de votre navigateur.
    </p>
    <p id="eb463040-8dd2-42ca-b9f9-5a4e316a8eaf" class="">
      Nous comprenons l’importance de protéger la vie privée des mineurs. Les
      sites Web précités, nos Services et prestations ne sont pas conçus pour
      les mineurs de 15 ans ou moins et ne leur sont pas destinés. Notre
      politique ne vise pas à collecter ou à conserver intentionnellement les
      données personnelles de personnes mineures.
    </p>
    <p id="f220cd4d-fcfd-4c18-82a8-87ad0584c60e" class=""></p>
    <h3 class="">
      <strong
        >L’utilisation que nous faisons de vos données personnelles</strong
      >
    </h3>
    <p id="57b226b4-aec5-4c45-b3b9-608d407c7669" class="">
      Nous utiliserons vos données personnelles pour vous fournir ou fournir nos
      Services liés au Petit Train de la Mure, ainsi que nos prestations liées
      au Musée La Mine Image.
    </p>
    <p id="9375188d-394d-4fc3-af3d-012197061e10" class="">
      Dans ce cadre, nous pouvons être amenés à utiliser vos données
      personnelles pour les besoins des échanges relatifs à notre organisation
      pour ces Services et ces prestations. Ces échanges peuvent intervenir avec
      vous, en tant qu’utilisateur-internaute de Sites Web, ou selon le cas en
      tant que client voyageur du Petit Train de la Mure ou visiteur du Musée La
      Mine Image, mais aussi nos fournisseurs de Services ou de prestations, ou
      bien encore les autorités compétentes.
    </p>
    <p id="f4da9103-edca-4a02-b4ef-c931d8fe01c0" class="">
      De manière plus particulière, nous pouvons être amenés à utiliser vos
      données personnelles aux fins ou en relation avec les autres opérations
      liées :
    </p>
    <ul id="a62e05c9-b784-471b-977b-758c532edd93" class="bulleted-list">
      <li>
        Aux outils, moyens et ressources fournis par notre Sous-Traitant, Logick
        S.A.S., pour les billets que vous achetez en ligne (par exemple, vous
        envoyer vos billets de train) ;
      </li>
    </ul>
    <ul id="9657a840-4b25-423d-87ff-be3fc72f55c7" class="bulleted-list">
      <li>
        Respecter les conditions des accords passés avec vous (par exemple, vous
        donner accès au train le jour de votre voyage) ;
      </li>
    </ul>
    <ul id="638c4e37-7028-47b2-b7a5-7c5b30a2ad5e" class="bulleted-list">
      <li>
        Gérer la relation commerciale et assurer un Service client (par exemple,
        modifier votre commande en cas d&#x27;erreur lors de votre réservation,
        vous prévenir en cas de modification sur vos trajets, changement
        d&#x27;horaire, problème technique, etc.) ;
      </li>
    </ul>
    <ul id="20988c03-5045-4df4-98fc-7bf9ede8cdbe" class="bulleted-list">
      <li>
        Améliorer, mettre à jour et perfectionner les Sites Web, ainsi que les
        produits et services qui y sont proposés à l&#x27;achat ;
      </li>
    </ul>
    <ul id="0a006a76-8791-4eb0-afa6-9993798dba32" class="bulleted-list">
      <li>
        Exercer des fonctions de comptabilité, d’audit, de rapprochement, de
        statistiques ainsi que d’autres fonctions internes ;
      </li>
    </ul>
    <ul id="a5644fe2-d828-4eb9-9b9e-e84935895e3b" class="bulleted-list">
      <li>
        Détecter des problèmes de sécurité et protéger contre les activités
        malveillantes, trompeuses, frauduleuses ou illégales (notamment,
        protéger contre la fraude et les autres activités illicites ; établir,
        exercer et défendre les droits juridiques ; respecter les obligations
        légales et réglementaires en vertu des lois et règlements en vigueur).
      </li>
    </ul>
    <p id="bd6b5074-2ef4-4795-a04e-3dcdbb33c2f1" class="">
      En plus des opérations précitées, nous pouvons aussi être amenés à
      collecter vos données personnelles via les sites Web, y compris le site
      Web de billetterie :
    </p>
    <ul id="95d579e5-ecad-4bbe-ad7a-9da33f2fc0cd" class="bulleted-list">
      <li>pour gérer et améliorer ces sites Web ;</li>
    </ul>
    <ul id="ede3ab22-f356-4e4b-9fb4-d91a334cb6f6" class="bulleted-list">
      <li>
        pour adapter le contenu de ces sites Web afin que votre expérience soit
        plus personnalisée, et attirer votre attention sur nos Services et
        prestations pouvant vous intéresser ;
      </li>
    </ul>
    <ul id="4b3591bc-2ce4-4cb5-bc44-3e930390eb17" class="bulleted-list">
      <li>pour gérer vos demandes transmises par ces sites et y répondre.</li>
    </ul>
    <p id="fcc0a76d-83ba-4ffe-81ca-3784075cf2e6" class="">
      Dans certaines circonstances, nous pouvons être aussi amenés à collecter
      certaines de vos données personnelles, notamment lorsque vous vous
      inscrivez à des Services ou prestations permettant d’obtenir des
      informations régulièrement mises à jour. Dans cette hypothèse, nos équipes
      sont susceptibles de vous inviter à des évènements pouvant vous intéresser
      ou vous adresser des informations relatives à nos activités.
    </p>
    <p id="f51481b1-b2c7-4c62-bb70-82e14da6152e" class=""></p>
    <h3 class="">
      <strong
        >Le fondement juridique de nos activités de traitement de
        données</strong
      >
    </h3>
    <p id="8482abdc-f4f9-4a74-ba05-a192a915fab5" class="">
      Nous utilisons vos données personnelles pour les finalités susmentionnées
      en raison :
    </p>
    <ul id="6ac595f5-891a-4a9b-8284-7eb704533a9f" class="bulleted-list">
      <li>
        (a) des contrats qui se forment par l’intermédiaire du service de
        billetterie et nos intérêts légitimes aux fins d’une prestation efficace
        des Services du Petit Train de la Mure et des prestations du Musée La
        Mine Image que nous vous fournissons ou que nous fournissons à nos
        clients ;
      </li>
    </ul>
    <ul id="ab41aea8-1a38-4678-b861-747646fd3772" class="bulleted-list">
      <li>
        (b) de nos intérêts légitimes aux fins de Services et prestations
        opérationnels et conformes que nous vous fournissons ou que nous
        fournissons à nos clients dans la mesure où ces intérêts ne sont pas
        disproportionnés au regard des droits des personnes concernées ;
      </li>
    </ul>
    <ul id="26f7f860-2214-4d34-a4af-a44df58821a8" class="bulleted-list">
      <li>
        (c) des obligations légales et réglementaires auxquelles nous sommes
        soumis, telles que par exemple, la tenue de registres à des fins
        d&#x27;information à la Commission Nationale de l&#x27;Informatique et
        des Libertés (« CNIL ») ou par application des règles de droit
        applicables ;
      </li>
    </ul>
    <ul id="9d14d8a8-c7c1-468a-8aee-fa44e7722e3d" class="bulleted-list">
      <li>
        (d) du fait que l&#x27;information est nécessaire pour vous fournir nos
        Services et prestations ou les fournir à nos clients ou prospects.
      </li>
    </ul>
    <p id="4d3e75fa-feee-4fb2-92ad-24aea8284ac6" class="">
      Si vous ne voulez plus recevoir de documentation marketing ou commerciale
      de notre part, veuillez cliquer sur le lien de désinscription figurant
      dans le courriel de communication ou envoyer un courrier à l’adresse :
    </p>
    <p id="cf04ebf4-0177-445d-adf8-29ce1cd0573a" class="">EDEIS S.A.S.</p>
    <p id="e8a0f923-a84d-407a-879e-b78b7ae661bc" class="">
      19 boulevard Paul Vaillant Couturier
    </p>
    <p id="745fd8be-9dc4-4957-86c8-014f1bbc6b11" class="">
      94200 Ivry-sur-Seine
    </p>
    <p id="ef2ab259-9235-4335-a138-4d6cfd87c74a" class="">France</p>
    <p id="1af78a72-9ea1-48ff-a305-5843099107d4" class=""></p>
    <h3 class="">
      <strong>À qui communiquons-nous vos données personnelles</strong>
    </h3>
    <p id="deeee8c3-e48f-46cc-9933-f861e6f02f91" class="">
      Pour l’une ou l’autre des finalités dont il est fait mention à la rubrique
      « L’utilisation que nous faisons de vos données personnelles » ci-dessus,
      nous pouvons communiquer des données personnelles vous concernant à la
      société Logick S.A.S., en qualité de destinataire de données d’éditrice du
      site de billetterie [<a
        href="https://billetterie.lepetittraindelamure.com"
        >https://billetterie.lepetittraindelamure.com</a
      >],<strong> </strong>et réciproquement, la société EDEIS S.A.S. peut
      recevoir des données personnelles des utilisateurs du site de billetterie.
    </p>
    <p id="417b5855-a708-4e09-8a8d-998b8323c475" class="">
      Au moment de payer, l’utilisateur du service de billetterie est redirigé
      vers la passerelle de paiement Paybox, qui est chargée de collecter et de
      traiter les informations et données de paiement.
    </p>
    <p id="9abc928f-cf59-45a3-908d-8da7239171f4" class="">
      Toutes les phases de paiement sont cryptées et protégées grâce au
      protocole SSL, couplé à de la monétique bancaire.
    </p>
    <p id="0cdf1402-316b-42d9-8c48-9106741afd84" class="">
      Plus d&#x27;informations disponibles sur le site https://www.paybox.com/
    </p>
    <p id="5aa9d3e4-63c5-4b21-830a-f521cc7bd334" class=""></p>
    <h3 class=""><strong>La protection de vos données personnelles</strong></h3>
    <p id="92de89e7-a61a-454a-b528-263c227542a0" class="">
      Nous avons recours à un ensemble de mesures organisationnelles et
      techniques afin de nous assurer que vos données personnelles sont
      protégées. Ces mesures comprennent notamment :
    </p>
    <ul id="1b288a82-bb44-4e47-a8e2-a227064a4298" class="bulleted-list">
      <li>
        la sensibilisation et la formation du personnel afin de s’assurer qu’il
        connaît nos obligations en matière de protection de la vie privée au
        moment du traitement de données personnelles ;
      </li>
    </ul>
    <ul id="bbe7069d-0ff2-4a0c-83f9-eaeb39dc7812" class="bulleted-list">
      <li>
        des contrôles administratifs et techniques de manière à nous assurer
        d’accéder uniquement aux données personnelles si nous en avons besoin ;
      </li>
    </ul>
    <ul id="e43cb48b-8461-4d19-9595-1f24112f72ad" class="bulleted-list">
      <li>
        des mesures de sécurité technologique, notamment des pares-feux, le
        cryptage et les logiciels antivirus ;
      </li>
    </ul>
    <ul id="a600982a-416b-4682-a6c8-44599821ce94" class="bulleted-list">
      <li>
        des mesures de sécurité physique dans nos locaux, notamment des
        contrôles d’accès.
      </li>
    </ul>
    <p id="429a7003-3b08-4205-96df-10dc31c0b40c" class="">
      Même si nous prenons des mesures de sécurité appropriées au moment de la
      collecte de vos données personnelles, la transmission de données par
      Internet (y compris par courriel) n’est jamais complètement sécurisée.
    </p>
    <p id="3833aac9-5f79-4f3f-bf32-b147624810c8" class=""></p>
    <h3 class="">
      <strong>La durée de conservation de vos données personnelles</strong>
    </h3>
    <p id="bae17adf-f7fa-44a7-bce3-0e305b2f1042" class="">
      Nous conserverons vos données personnelles dans nos systèmes pendant la
      plus longue des durées suivantes : (i) aussi longtemps que nécessaire pour
      les opérations et les finalités de traitement concernées, (ii) pendant les
      durées de conservation, d’archivage et de prescription autorisées par la
      loi, (iii) la fin de la durée de prescription applicable à la suite d’un
      litige ou d’une enquête à l’égard de l’une de nos offres ou propositions,
      ou de l’un de nos services.
    </p>
    <p id="49538764-5416-4095-bae0-66683848d76f" class=""></p>
    <h3 class=""><strong>Vos droits</strong></h3>
    <p id="81db6097-bf3e-4d43-9367-1785adb00cac" class="">
      Vous avez divers droits relativement à vos données personnelles, notamment
      les suivants :
    </p>
    <ul id="fe5e46f2-61c2-48c9-b8b9-e83607493221" class="bulleted-list">
      <li>
        Obtenir la confirmation que nous traitons vos données personnelles et
        obtenir une copie des données personnelles que nous conservons vous
        concernant.
      </li>
    </ul>
    <ul id="496159f0-558a-4332-88aa-839186418b6e" class="bulleted-list">
      <li>
        Nous demander de mettre à jour vos données personnelles détenues ou de
        corriger des données personnelles erronées ou incomplètes.
      </li>
    </ul>
    <ul id="b6cd4ea4-3d8d-434f-a09c-9fe540a48c59" class="bulleted-list">
      <li>
        Nous demander de supprimer les données personnelles que nous conservons
        vous concernant, ou de limiter l’utilisation que nous en faisons.
      </li>
    </ul>
    <ul id="7bdfdd9e-c12f-4b46-900a-acb985b7a25a" class="bulleted-list">
      <li>
        Retirer votre consentement nous autorisant à traiter vos données
        personnelles (dans la mesure où ce traitement est assujetti à un
        consentement).
      </li>
    </ul>
    <ul id="5125b1b2-03c8-4561-8a6c-c91a7fdc0513" class="bulleted-list">
      <li>
        Dans la mesure prescrite par la loi ou un règlement applicable, recevoir
        une copie des données personnelles que vous nous avez transmises, sous
        une forme structurée, couramment utilisée et lisible par une machine et
        en vue de les transmettre à une autre partie (dans la mesure où ce
        traitement est assujetti à un consentement ou un contrat).
      </li>
    </ul>
    <ul id="2f59149d-d531-401b-92c0-159cf600b953" class="bulleted-list">
      <li>Vous opposez au traitement de vos données personnelles.</li>
    </ul>
    <p id="378677dd-c00d-49fa-8a87-6cc4b4e32d25" class="">
      Pour exercer vos droits, ou si vous avez des questions concernant
      l’utilisation de vos données personnelles, vous pouvez contacter :
    </p>
    <p id="606b5508-7ee2-4a09-96df-3311b190aa15" class="">EDEIS S.A.S.</p>
    <p id="a126e475-ddd7-459b-92c1-2035b3993e2b" class="">
      19 boulevard Paul Vaillant Couturier
    </p>
    <p id="ee6fd897-c38d-4e76-9d29-b2e81362a629" class="">
      94200 Ivry-sur-Seine
    </p>
    <p id="a48d0d72-3382-43b8-bcec-0dc4374b89ce" class="">France</p>
    <p id="dfc2820d-1fc0-4843-9748-18b40a1f3f5b" class="">
      en indiquant vos nom, prénom, adresse et si possible votre numéro de
      réservation.
    </p>
    <p id="e9eac43c-91b1-4bd6-bd53-8d9b5f4f7716" class="">
      Vous pouvez également contacter EDEIS S.A.S. à l’adresse susmentionnée si
      vous souhaitez porter plainte contre une atteinte à votre vie privée.
    </p>
    <p id="b5e257dd-e717-4a70-85a9-ff2ffa24735c" class="">
      Conformément à la législation et à la réglementation en vigueur, votre
      courrier doit être signé et accompagné de la photocopie d&#x27;un titre
      d&#x27;identité portant votre signature et préciser l&#x27;adresse à
      laquelle doit vous parvenir la réponse.
    </p>
    <p id="7c3960e7-a12a-4f9e-8106-44cb42738577" class=""></p>
    <h3 class=""><strong>Votre droit de porter plainte</strong></h3>
    <p id="7fae549a-8595-4239-90b1-5bb4e61939f8" class="">
      En cas d’insatisfaction relative à la manière dont nous avons traité vos
      données personnelles ou pour toute question ou requête soulevée auprès de
      nos services qui serait restée infructueuse, vous êtes en droit
      d’introduire une réclamation auprès de la CNIL, à l’adresse postale
      suivante :
    </p>
    <p id="09accf90-23c7-43ae-bf17-9ca8744eae4d" class="">
      3 Place de Fontenoy - TSA 80715 - 75334
    </p>
    <p id="7530c0d2-4a1b-41ab-9828-310b38d9f0a1" class="">PARIS CEDEX 07</p>
    <p id="be0176bd-3ee2-42fb-a494-ad9360e50b4f" class="">
      Tél : 01 53 73 22 22
    </p>
    <p id="ecbbb0e4-22fa-45cd-aba4-f843c1cb0c9c" class=""></p>
    <h3 class="">
      <strong>Modification de la politique des données personnelles</strong>
    </h3>
    <p id="e7104e4e-bff3-404e-ac04-e821c682ca25" class="">
      Nous pouvons modifier cette politique de protection des données
      personnelles de temps à autre (en raison notamment de l’évolution
      législative et/ou règlementaire).
    </p>
    <p id="4830aa7b-68b0-436c-94a9-8d559c0bc3c2" class="">
      Si nous apportons des changements à cette politique de protection des
      données personnelles, nous modifierons la date de révision en haut de la
      page. La version modifiée de cette politique de protection des données
      personnelles sera applicable à compter de cette date. Ainsi, nous vous
      encourageons à consulter régulièrement cette politique afin de vous tenir
      informé(e) de la manière dont nous protégeons vos données personnelles.
    </p>
    <p id="0a196c19-7dfe-4904-a3f3-0d78c4bb31ea" class=""></p>
  </div>
</article>
