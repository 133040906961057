import { Component, Input, OnInit } from "@angular/core"
import { TimeSlotsModel } from "../../models/timeslots"
import { DateTz } from "../../dates/DateTz"

@Component({
  selector: "app-dependencies-summary",
  templateUrl: "./dependencies-summary.component.html",
  styleUrls: ["./dependencies-summary.component.scss"],
})
export class DependenciesSummaryComponent implements OnInit {
  @Input() data: Array<TimeSlotsModel> = []
  @Input() hasMuseumStop = false
  constructor() {}

  ngOnInit(): void {}

  getPathDuration(start: DateTz, end: DateTz): string {
    const delta = end.date.diff(start.date, "s")
    const hour = parseInt(delta / 60 / 60 + "", 10)
    const minute = parseInt((delta - hour * 60 * 60) / 60 + "", 10)
    return (
      (+hour > 0 ? +hour + "h " : "") + (+minute > 0 ? +minute + "min" : "")
    )
  }
}
