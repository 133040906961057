<div class="container">
  <div class="container-left">
    <div class="error" *ngIf="paymentRefused">
      Le paiement a été annulé, merci de reéssayer.
    </div>
    <h2>Récapitulatif</h2>
    <p>Votre adresse email de contact : {{ orderSummary.email }}</p>
    <br />
    <div>
      <b>{{ orderSummary.offerName }}</b>
    </div>
    <div class="date">
      {{ orderSummary.date.format("dddd D MMMM YYYY") }}
    </div>

    <app-dependencies-summary
      [data]="dependencies"
      [hasMuseumStop]="orderSummary.hasMuseumStop"
    ></app-dependencies-summary>

    <br />
    <div>
      <b>{{ orderSummary.passengersNumber }} voyageur(s)</b>
    </div>
    <br />
    <div *ngFor="let row of orderSummary.passengersDetails">
      <span *ngIf="row.quantity > 0"> {{ row.quantity }} {{ row.label }} </span>
    </div>
    <br />
    <div><b>Montant</b></div>
    <br />
    <div>
      <b
        >Total TTC {{ orderSummary.amountTTC | currency : "EUR" : "symbol" }}</b
      >
      <!--            dont TVA {{orderSummary.vatAmount | currency:'EUR':'symbol'}}-->
    </div>
    <br />
  </div>
</div>

<ng-container *ngIf="paymentRefused; else elseBlock2">
  <div class="error">Le paiement a été annulé, merci de reéssayer.</div>

  <div class="buttons">
    <button
      pButton
      pRipple
      type="button"
      label="Annuler et effectuer une autre réservation"
      (click)="newOrder()"
      icon="pi pi-arrow-right"
      iconPos="right"
      class="large p-button-outlined"
    ></button>
    <button
      pButton
      pRipple
      type="button"
      label="Payer"
      (click)="next()"
      icon="pi pi-arrow-right"
      iconPos="right"
      class="large right"
    ></button>
  </div>
</ng-container>
<ng-template #elseBlock2>
  <app-next-button (click2)="next()" label="Payer"></app-next-button>
</ng-template>
