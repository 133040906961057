import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import { TimeSlotsModel } from "../../models/timeslots"
import { VelOrderService } from "../../services/vel-order.service"

export interface TimeslotChoicesInput {
  id: string
  title: string
  slots: Array<TimeSlotsModel>
  filteredSlots: Array<TimeSlotsModel>
}

@Component({
  selector: "app-timeslot-choice",
  templateUrl: "./timeslot-choice.component.html",
  styleUrls: ["./timeslot-choice.component.scss"],
})
export class TimeslotChoiceComponent implements OnInit {
  @Input() slots: Array<TimeSlotsModel> = []
  @Input() title = ""
  @Input() loading = false
  @Output() change = new EventEmitter<string>()
  selectedId = ""

  constructor(private orderService: VelOrderService) {}

  ngOnInit(): void {}

  selectSlot(slot: TimeSlotsModel): void {
    this.selectedId = slot.id
    this.orderService.setTimeSlots(slot.id)
    this.selectedId = ""
    this.change.emit(slot.id)
  }

  isSelected(slotId: string): boolean {
    if (this.selectedId) {
      return this.selectedId === slotId
    }
    return this.orderService.isTimeSlotSelected(slotId)
  }

  isPast(slot: TimeSlotsModel): boolean {
    return slot.start_at.date.isBefore(new Date())
  }
}
