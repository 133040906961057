import { Component, OnInit } from "@angular/core"
import { VelOrderService } from "../../services/vel-order.service"
import { VelApiService } from "../../services/vel-api.service"
import { ActivatedRoute, Router } from "@angular/router"
import { MessageService } from "primeng/api"
import { OrderFactory } from "../../models/order.factory"
import { OrderModel } from "../../models/order"
import { TimeSlotsModel } from "../../models/timeslots"

@Component({
  selector: "app-payment-page",
  templateUrl: "./payment-page.component.html",
  styleUrls: ["./payment-page.component.scss"],
})
export class PaymentPageComponent implements OnInit {
  orderSummary = OrderFactory.getEmptySummary()
  paymentRefused = false
  orderId = ""
  dependencies: Array<TimeSlotsModel> = []
  currentOrder!: OrderModel

  constructor(
    private velOrderService: VelOrderService,
    private velApiService: VelApiService,
    private route: ActivatedRoute,
    private router: Router,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.orderId = this.route.snapshot.paramMap.get("orderId") || ""
    if (this.orderId) {
      this.paymentRefused = true
      this.velOrderService.fetchOrder(this.orderId, false).then((order) => {
        this.currentOrder = order // order form the url
        this.buildSummary(order)
        this.messageService.add({
          severity: "error",
          summary: "Erreur",
          detail: "Votre paiement a été annulé.",
        })
      })
    } else {
      if (this.velOrderService.checkOrderConsistency(5)) {
        const order = this.velOrderService.sessionOrder
        if (!order.order_id) {
          this.messageService.add({
            severity: "error",
            summary: "Erreur",
            detail: "Impossible d'afficher le recapitulatif.",
          })
        }
        this.velOrderService.fetchOrder(order.order_id).then((result) => {
          this.currentOrder = result
          this.buildSummary(this.currentOrder)
        })
      }
    }
  }

  buildSummary(order: OrderModel): void {
    this.velOrderService.getTimeslotDependencies(order).then((result) => {
      this.dependencies = result.dependencies
      this.orderSummary = OrderFactory.toSummaryOrder(order)
    })
  }

  next(): void {
    this.velOrderService
      .confirmOrderRow()
      .then((_) => this.velOrderService.makePayment(this.currentOrder))
  }

  newOrder(): void {
    this.velOrderService.cancelOrder(this.currentOrder)
    this.velOrderService.resetOrder()
    this.router.navigateByUrl("/voyages")
  }
}
