<h2>Sélectionnez votre voyage</h2>
<div class="info">
  <a
    href="https://lepetittraindelamure.com/restaurants/le-panoramique/"
    target="_blank"
    ><img src="/assets/info-restaurant-le-panoramique-2023.png"
  /></a>
</div>
<!--<div class="info" >
   <div style="background-color:#ffbfbf;padding:10px;text-align: center;">
        En septembre, le train n’effectue pas d’arrêt au Musée Mine Image, mais le musée est ouvert pour des visites guidées chaque jour.<br>
Profitez du tarif préférentiel, en présentant vos billets de train directement à la caisse du musée.<br>
Tarif réduit appliqué uniquement sur présentation du billet de train, valide sur la semaine en cours.<br>
Il est nécessaire de réserver votre créneau de visite au <strong>04 76 30 68 74</strong>.<br>
<a href="https://lepetittraindelamure.com/visite-du-musee-mine-image/">https://lepetittraindelamure.com/visite-du-musee-mine-image/</a>
    </div>
</div>-->
<div class="offers">
  <app-product [model]="product" *ngFor="let product of products"></app-product>
</div>
