import { OrderModel, OrderSummary } from "./order"
import { DateTz } from "../dates/DateTz"

export class OrderFactory {
  static getEmptySummary(): OrderSummary {
    return {
      passengersDetails: [],
      offerName: "",
      hasMuseumStop: false,
      date: new DateTz(),
      passengersNumber: 0,
      amountTTC: 0,
      vatAmount: 0,
      email: "",
    }
  }

  static toSummaryOrder(order: OrderModel): OrderSummary {
    const amount = order.net_price.amount / 100

    const item = order.items[0]
    return {
      amountTTC: amount,
      vatAmount: (amount * 0.1) / 1.1,
      passengersNumber: item?.prices.reduce(
        (acc, value) => acc + value.quantity,
        0
      ),
      date: new DateTz(item?.indicative_date),
      hasMuseumStop: item?.label.includes("musée"), // FIXME Alban
      offerName: item.label,
      passengersDetails: item?.prices
        .filter((x) => x.quantity > 0)
        .map((x) => {
          return {
            quantity: x.quantity,
            label: `voyageur${x.quantity > 1 ? "s" : ""} - ` + x.rate_label,
          }
        }),
      email: order.customer.email,
    }
  }
}
